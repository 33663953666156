@font-face {
  font-family: 'Gilroy';
  src: url('/fonts/Gilroy-BoldItalic.woff2') format('woff2'),
      url('/fonts/Gilroy-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Gilroy';
  src: url('/fonts/Gilroy-ExtraBoldItalic.woff2') format('woff2'),
      url('/fonts/Gilroy-ExtraBoldItalic.woff') format('woff');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Gilroy';
  src: url('/fonts/Gilroy-Light.woff2') format('woff2'),
      url('/fonts/Gilroy-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('/fonts/Gilroy-Regular.woff2') format('woff2'),
      url('/fonts/Gilroy-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('/fonts/Gilroy-ExtraBold.woff2') format('woff2'),
      url('/fonts/Gilroy-ExtraBold.woff') format('woff');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('/fonts/Gilroy-RegularItalic.woff2') format('woff2'),
      url('/fonts/Gilroy-RegularItalic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Gilroy';
  src: url('/fonts/Gilroy-Bold.woff2') format('woff2'),
      url('/fonts/Gilroy-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('/fonts/Gilroy-LightItalic.woff2') format('woff2'),
      url('/fonts/Gilroy-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
}